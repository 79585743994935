<script>
import { mapState, mapActions } from 'vuex'

import { toastMixins } from '@/mixins'
import branchService from '@/services/branch/branch'

import MenuBar from './components/MenuBar.vue'

export default {
  mixins: [toastMixins],
  components: { MenuBar },
  data() {
    return {
      fields: [
        { type: 'code', title: 'รหัสสาขา', width: '25%' },
        { type: 'name', title: 'ชื่อสาขา', width: '30%' },
        { type: 'description', title: 'คำอธิบายสาขา', width: '45%' }
      ],
      branches: [],
      branchSelected: null,
      rowOpenCollections: [],
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      appLoading: (state) => state.appLoading
    })
  },
  created() {
    this.fetchBranchTree()
  },
  methods: {
    ...mapActions(['setAppLoading']),
    async fetchBranchTree() {
      this.setAppLoading(true)
      const res = await branchService.getBranchTree()
      this.branches = res.data.data
      this.restoreBranchOpenCloseState()
      try {
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
      this.setAppLoading(false)
    },
    updateTableHandler() {
      this.branchSelected = null
      this.fetchBranchTree()
    },
    restoreBranchOpenCloseState() {
      this.branches.forEach((branch) => {
        this.restoreBranchOpenCloseStateRecursive(branch)
      })
    },
    restoreBranchOpenCloseStateRecursive(branch) {
      if (this.rowOpenCollections.includes(branch.id)) {
        branch.state = 'open'
      }
      if (branch.children.length > 0) {
        branch.children.forEach((child) => {
          this.restoreBranchOpenCloseStateRecursive(child)
        })
      }
    },
    rowSelectedHandler(row) {
      this.branchSelected = row
    },
    rowExpandHandler(row) {
      if (row.children.length > 0) {
        this.rowOpenCollections.push(row.id)
      }
    },
    rowCollapseHandler(row) {
      this.rowOpenCollections = this.rowOpenCollections.filter(
        (el) => el !== row.id
      )
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="จัดการสาขา"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar
          :branchSelectedProp="branchSelected"
          @updateTable="updateTableHandler"
        ></menu-bar>
      </b-col>
    </b-row>

    <!-- tree table -->
    <b-row class="mt-3">
      <b-col v-if="appLoading">
        <div
          class="d-flex justify-content-center align-items-center bg-light p-5"
        >
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
          <span class="ml-2">กำลังโหลดข้อมูล ...</span>
        </div>
      </b-col>
      <b-col v-else>
        <TreeGrid
          style="height: 457px"
          :data="branches"
          idField="id"
          treeField="code"
          @rowSelect="rowSelectedHandler"
          @rowExpand="rowExpandHandler"
          @rowCollapse="rowCollapseHandler"
        >
          <GridColumn
            v-for="field in fields"
            :key="field.type"
            :field="field.type"
            :title="field.title"
            :width="field.width"
          ></GridColumn>
        </TreeGrid>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
