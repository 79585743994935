<script>
import { toastMixins } from '@/mixins'

import CreateModal from './CreateModal.vue'
import ReadModal from './ReadModal.vue'
import UpdateModal from './UpdateModal.vue'
import DeleteModal from './DeleteModal.vue'

export default {
  mixins: [toastMixins],
  props: {
    branchSelectedProp: Object
  },
  components: { CreateModal, ReadModal, UpdateModal, DeleteModal },
  data() {
    return {
      buttonClass:
        'shadow m-1 d-flex justify-content-center align-items-center',
      iconClass: 'mr-2',
      modalShow: false,
      modalTitle: null,
      modalThemeColor: null,
      modalCurrent: null,
      modalHeaderTextVariant: null,
      errMsg: null
    }
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'CREATE':
          this.modalShow = true
          this.modalTitle = 'สร้างสาขาใหม่'
          this.modalThemeColor = 'success'
          this.modalCurrent = 'create-modal'
          this.modalHeaderTextVariant = 'light'
          break
        case 'UPDATE':
          if (!this.branchSelectedProp) {
            alert('เลือกสาขาที่ต้องการจะแก้ไข')
            return
          }
          this.modalShow = true
          this.modalTitle = 'แก้ไขสาขาเดิม'
          this.modalThemeColor = 'primary'
          this.modalCurrent = 'update-modal'
          this.modalHeaderTextVariant = 'light'
          break
        case 'DELETE':
          if (!this.branchSelectedProp) {
            alert('เลือกสาขาที่ต้องการจะลบ')
            return
          }
          this.modalShow = true
          this.modalTitle = 'ลบสาขา'
          this.modalThemeColor = 'danger'
          this.modalCurrent = 'delete-modal'
          this.modalHeaderTextVariant = 'light'
          break
        case 'READ':
          if (!this.branchSelectedProp) {
            alert('เลือกสาขาที่ต้องการดูรายละเอียด')
            return
          }
          this.modalShow = true
          this.modalTitle = 'รายละเอียดสาขา'
          this.modalThemeColor = 'warning'
          this.modalCurrent = 'read-modal'
          this.modalHeaderTextVariant = 'dark'
          break
        case 'EXIT':
          this.$router.push({
            name: 'Admin.Home'
          })
          break
      }
    },
    closeModalHandler(event) {
      switch (event.type) {
        case 'CLOSE':
          this.modalShow = false
          break
        case 'CLOSE_WITH_SUCCESS':
          this.modalShow = false
          this.mxDisplaySuccessMessage(event.message)
          this.$emit('updateTable')
          break
        case 'DISPLAY_ERROR':
          this.errMsg = event.message
          break
        default:
          this.modalShow = false
          break
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <!-- CREATE BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="success"
          @click="buttonClicked('CREATE')"
          v-b-tooltip.hover.bottom.v-success="'สร้างสาขาใหม่'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="plus"></b-icon>
          <span class="text-sm">สร้าง</span>
        </b-button>
      </div>

      <!-- UPDATE BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="primary"
          @click="buttonClicked('UPDATE')"
          v-b-tooltip.hover.bottom.v-primary="'แก้ไขสาขา'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="pencil-square"></b-icon>
          <span class="text-sm">แก้ไข</span>
        </b-button>
      </div>

      <!-- DELETE BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('DELETE')"
          v-b-tooltip.hover.bottom.v-danger="'ลบสาขา'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="trash"></b-icon>
          <span class="text-sm">ลบ</span>
        </b-button>
      </div>

      <!-- READ BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="warning"
          @click="buttonClicked('READ')"
          v-b-tooltip.hover.bottom.v-warning="'ดูข้อมูลสาขา'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="file-earmark-text"
          ></b-icon>
          <span class="text-sm">รายละเอียด</span>
        </b-button>
      </div>

      <!-- EXIT BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('EXIT')"
          v-b-tooltip.hover.bottom.v-danger="'ออกจากหน้านี้'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="box-arrow-right"
          ></b-icon>
          <span class="text-sm">ออก</span>
        </b-button>
      </div>

      <!-- MODAL DISPLAY -->
      <b-modal
        v-model="modalShow"
        :title="modalTitle"
        :header-bg-variant="modalThemeColor"
        :header-text-variant="modalHeaderTextVariant"
        :hide-footer="true"
        :size="'md'"
      >
        <component
          :is="modalCurrent"
          :branchSelectedProp="branchSelectedProp"
          @closeModal="closeModalHandler"
        ></component>
      </b-modal>
    </div>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style scoped>
.tooltip {
  background-color: transparent;
  border-color: transparent;
}
</style>
