<script>
import CreateUpdateReadForm from './CreateUpdateReadForm.vue'

export default {
  props: {
    branchSelectedProp: Object
  },
  components: { CreateUpdateReadForm },
  methods: {
    closeModalHandler(payload) {
      this.$emit('closeModal', payload)
    }
  }
}
</script>

<template>
  <create-update-read-form
    modeProp="UPDATE"
    :branchSelectedProp="branchSelectedProp"
    :variantProp="'primary'"
    @closeModal="closeModalHandler"
  ></create-update-read-form>
</template>

<style></style>
