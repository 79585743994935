<script>
import branchService from '@/services/branch/branch'

export default {
  props: {
    branchSelectedProp: Object
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    getTargetBranchInformation() {
      if (this.branchSelectedProp) {
        const { code, name, description, parentId } = this.branchSelectedProp
        return [
          { key: 'code', value: code || '-', label: 'รหัสสาขา' },
          { key: 'name', value: name || '-', label: 'ชื่อสาขา' },
          {
            key: 'description',
            value: description || '-',
            label: 'คำอธิบายสาขา'
          },
          {
            key: 'parentId',
            value: parentId || '-',
            label: 'สาขาแม่'
          }
        ]
      }
      return []
    }
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'CONFIRM':
          if (this.branchSelectedProp) {
            this.deleteBranchById(this.branchSelectedProp.id)
          }
          break
        case 'CANCEL':
          this.$emit('closeModal', {
            type: 'CLOSE'
          })
          break
        default:
          break
      }
    },
    async deleteBranchById(id) {
      this.isLoading = true
      try {
        await branchService.deleteBranchById(id)
        this.$emit('closeModal', {
          type: 'CLOSE_WITH_SUCCESS',
          message: 'ลบสาขาสำเร็จ'
        })
      } catch (err) {
        this.$emit('closeModal', {
          type: 'DISPLAY_ERROR',
          message: err.response.data.thMessage
        })
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
  <div v-if="branchSelectedProp">
    <!-- content -->
    <b-row align-v="start">
      <!-- trash icon -->
      <b-col class="col-3">
        <b-icon
          icon="trash-fill"
          variant="danger"
          class="mt-2 icon-style"
        ></b-icon>
      </b-col>

      <!-- information -->
      <b-col class="col-9">
        <h5>รายละเอียดของสาขาที่จะลบ</h5>
        <div
          class="d-flex mt-3"
          v-for="info in getTargetBranchInformation"
          :key="info.key"
        >
          <span> {{ info.label }}: </span>
          <span class="ml-2 font-weight-bold">
            {{ info.value }}
          </span>
        </div>
      </b-col>
    </b-row>

    <!-- hover line -->
    <hr />

    <!-- buttons -->
    <b-row align-h="end">
      <b-col class="col-3">
        <b-button
          class="w-100"
          variant="danger"
          @click="buttonClicked('CONFIRM')"
          :disabled="isLoading"
        >
          <b-spinner v-if="isLoading" variant="light" small></b-spinner>
          <span v-else>ลบ</span>
        </b-button>
      </b-col>
      <b-col class="col-3">
        <b-button
          class="w-100"
          variant="outline-danger"
          @click="buttonClicked('CANCEL')"
          :disabled="isLoading"
        >
          ยกเลิก
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.icon-style {
  width: 100%;
  height: 100%;
}
</style>
