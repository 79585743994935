<script>
import CreateUpdateReadForm from './CreateUpdateReadForm.vue'

export default {
  props: {
    branchSelectedProp: Object
  },
  components: {
    CreateUpdateReadForm
  }
}
</script>

<template>
  <create-update-read-form
    modeProp="READ"
    :variantProp="'warning'"
    :branchSelectedProp="branchSelectedProp"
  ></create-update-read-form>
</template>

<style></style>
