<script>
import { isNull, isEqual } from 'lodash'

import { toastMixins } from '@/mixins'
import branchService from '@/services/branch/branch'

export default {
  mixins: [toastMixins],
  props: {
    modeProp: String, // CREATE , READ , UPDATE
    variantProp: String,
    branchSelectedProp: Object
  },
  data() {
    return {
      initData: {
        id: null,
        code: null,
        name: null,
        description: null,
        parentId: null
      },
      updateData: {},
      isLoading: false,
      branchOptions: [],
      errMsg: null
    }
  },
  computed: {
    dataChanged() {
      return !isEqual(this.initData, this.updateData)
    }
  },
  watch: {
    branchSelectedProp() {
      this.initializeData()
    }
  },
  async created() {
    await Promise.all([this.fetchBranchOptions()])
    this.initializeData()
  },
  methods: {
    async fetchBranchOptions() {
      this.isLoading = true
      try {
        const res = await branchService.getBranchOptions()
        this.branchOptions = res.data.data
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
      this.isLoading = false
    },
    initializeData() {
      if (!isNull(this.branchSelectedProp)) {
        const {
          id,
          code,
          name,
          description,
          parentId
        } = this.branchSelectedProp
        const dataObj = { id, code, name, description, parentId }

        if (this.modeProp === 'CREATE') {
          this.initData.parentId = id
          this.updateData = { ...this.initData }
          this.updateData.parentId = id
        } else {
          this.initData = { ...dataObj }
          this.updateData = { ...dataObj }
        }
      } else {
        this.updateData = { ...this.initData }
      }
    },
    async buttonClicked(type) {
      switch (type) {
        case 'CONFIRM':
          this.isLoading = true
          try {
            if (this.modeProp === 'CREATE') {
              await this.createNewBranch()
              this.$emit('closeModal', {
                type: 'CLOSE_WITH_SUCCESS',
                message: 'สร้างสาขาใหม่สำเร็จ'
              })
            } else if (this.modeProp === 'UPDATE') {
              await this.updateExistBranch()
              this.$emit('closeModal', {
                type: 'CLOSE_WITH_SUCCESS',
                message: 'แก้ไขสาขาเดิมสำเร็จ'
              })
            }
          } catch (err) {
            this.errMsg = err.response.data.thMessage
          }
          this.isLoading = false
          break
        case 'CANCEL':
          this.$emit('closeModal', { type: 'CLOSE' })
          break
        default:
          break
      }
    },
    async createNewBranch() {
      const { code, name, description, parentId } = this.updateData
      const payload = { code, name, description, parentId }
      await branchService.createNewBranch(payload)
    },
    async updateExistBranch() {
      const { id, code, name, description, parentId } = this.updateData
      const payload = { code, name, description, parentId }
      await branchService.updateBranchById(id, payload)
    }
  }
}
</script>

<template>
  <div>
    <!-- content -->
    <b-row>
      <!-- code -->
      <b-col cols="12">
        <div class="d-flex align-items-center mb-3">
          <div class="text-nowrap w-25 mr-3">
            <span>รหัสสาขา</span>
          </div>
          <div class="w-75">
            <b-input
              v-model="updateData.code"
              :disabled="isLoading || modeProp === 'READ'"
            ></b-input>
          </div>
        </div>
      </b-col>

      <!-- name -->
      <b-col cols="12">
        <div class="d-flex align-items-center mb-3">
          <div class="text-nowrap w-25 mr-3">
            <span>ชื่อสาขา</span>
          </div>
          <div class="w-75">
            <b-input
              v-model="updateData.name"
              :disabled="isLoading || modeProp === 'READ'"
            ></b-input>
          </div>
        </div>
      </b-col>

      <!-- description -->
      <b-col cols="12">
        <div class="d-flex align-items-center mb-3">
          <div class="text-nowrap w-25 mr-3">
            <span>คำอธิบายสาขา</span>
          </div>
          <div class="w-75">
            <b-input
              v-model="updateData.description"
              :disabled="isLoading || modeProp === 'READ'"
            ></b-input>
          </div>
        </div>
      </b-col>

      <!-- parentId -->
      <b-col cols="12">
        <div class="d-flex align-items-center mb-3">
          <div class="text-nowrap w-25 mr-3">
            <span>สาขาแม่</span>
          </div>
          <div class="w-75">
            <b-form-select
              v-model="updateData.parentId"
              :options="branchOptions"
              :disabled="isLoading || modeProp === 'READ'"
            ></b-form-select>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- hover line -->
    <hr v-if="modeProp !== 'READ'" />

    <!-- buttons -->
    <b-row align-h="end" v-if="modeProp !== 'READ'">
      <b-col class="col-3">
        <b-button
          class="w-100"
          :variant="variantProp"
          @click="buttonClicked('CONFIRM')"
          :disabled="isLoading || !dataChanged"
        >
          <b-spinner v-if="isLoading" variant="light" small></b-spinner>
          <span v-else>บันทึก</span>
        </b-button>
      </b-col>
      <b-col class="col-3">
        <b-button
          class="w-100"
          :variant="`outline-${variantProp}`"
          @click="buttonClicked('CANCEL')"
          :disabled="isLoading"
        >
          ยกเลิก
        </b-button>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
